import { BrowserRouter as Router } from 'react-router-dom';
import loadable from '@loadable/component';
import { ScrollToTop } from 'components';
import { useSessionContext } from 'hooks';
import { Elements } from '@stripe/react-stripe-js';
import {PathRouteProps} from 'react-router';

export interface PrivateRouteProps extends PathRouteProps {
  redirect?: string;
}

const PublicRoutes = loadable(() => import('./Public'));

const AuthRoutes = loadable(() => import('./Auth'));

const DashboardRoutes = loadable(() => import('./Dashboard'));

const BuyerRoutes = loadable(() => import('./Buyer'));

//const SellerRoutes = loadable(() => import('./Seller'));

const SupportRoutes = loadable(() => import('./Support'));

//const EventRoutes = loadable(() => import('./Event'));

//const StoreRoutes = loadable(() => import('./Store'));

const KwixlChatRoutes = loadable(() => import('./KwixlChat'));

const ProxyRoutes = loadable(() => import('./Proxy'));

export const ROUTES = {
    home: '/',
    homeAlt: '/home',
    signup: '/signup',
    pricing: '/pricing',
    channel: '/channel',
    facebook: '/facebook',
  
    // Auth
    signin: '/auth/signin',
    signout: '/auth/signout',
    reminder: '/auth/reminder',
    auth_mailchimp: '/auth/mailchimp',
    yt_auth: '/auth/oauth/yt',
    verify_method: (method: string) => `/auth/verify/${method}`,
    confirm: '/auth/confirm',
    event_login: (id: string, token: string) => `/auth/event/${id}/${token}`,
    facebook_connect: '/auth/facebook',
  
    // Account
    account: '/account',
    onboard: '/account/onboard',
    onboard_status: '/account/onboard/status',
  
    // Demo
    demo: '/demo',
    demo_buyers: '/demo/buyers',
    demo_selling: '/demo/selling',
    demo_formats: '/demo/formats',
    demo_video: '/demo_video',
    demo_facebook: '/demo/facebook',
    demo_enter: '/demo/enter',
  
    // Store
    store: (vanity: string) => `/store/${vanity}`,
    store_go: (vanity: string) => `/go/${vanity}`,
  
    // Support 
    support: '/support',
    
    // Presenter
    presenter: '/presenter',
    presenter_event_live: (eventId = ':eventId') => `/presenter/event/${eventId}/live`,
    presenter_events: '/presenter/events',
  
    // Seller
    cp: '/cp',
    cp_org: '/cp/org',
    cp_org_stats: '/cp/org/stats',
    cp_settings: '/cp/settings',
    cp_settings_event: '/cp/settings/event',
    cp_settings_address: '/cp/settings/address',
    cp_settings_payment: '/cp/settings/payment',
    cp_settings_page: '/cp/settings/page',
    cp_settings_policy: '/cp/settings/policy',
    cp_settings_shipping: '/cp/settings/shipping',
    cp_billing: '/cp/billing',
    cp_billing_invoice: (invoiceId = ':invoiceId') => `/cp/billing/invoice/${invoiceId}`,

    /* FACEBOOK */
    cp_facebook: '/cp/fb',
    cp_facebook_pages: '/cp/fb/pages',
    cp_facebook_posts: `/cp/fb/posts`,
    cp_facebook_users: `/cp/fb/users`,
    cp_facebook_page_replies: (pageId = ':pageId') => `/cp/fb/${pageId}/replies`,
    cp_facebook_page_users: (pageId = ':pageId') => `/cp/fb/${pageId}/users/`,
    cp_facebook_page_posts: (pageId: string) => `/cp/fb/${pageId}/posts`,
    cp_facebook_page_archived_posts: (pageId = ':pageId') => `/cp/fb/${pageId}/posts/archived`,
    cp_facebook_archived_posts: '/cp/fb/posts/archived',
    cp_facebook_comments: `/cp/fb/comments`,
    cp_facebook_post_activity: (postId: string) => `/cp/fb/${postId}/activity`,
    cp_facebook_settings: `/cp/fb/settings`,
    
    /* TIKTOK */
    cp_tiktok: '/cp/tiktok',
    
    cp_inventory: '/cp/inventory',

    application: '/selling/application',
    categories: '/categories',
    contact: '/contact',
    faq: '/faq',
    privacy: '/privacy',
    terms: '/terms',
    blocked: '/blocked',
    page404: '/404',
    events: '/events',
    auth: '/auth',
    loginlink: '/auth/loginlink',
    instagram_auth: '/auth/ig',
    tiktok_auth: '/auth/tiktok',
    me: '/cp',
    // My routes
    my: '/cp',
    my_notifications: '/cp/notifications',
    my_messages: '/cp/messages',
    my_messages_thread: (threadId: string) => `/cp/messages/${threadId}`,
    my_profile: '/cp/profile',
    my_invoices: '/cp/invoices',
    my_invoice: (invoiceId: string) => `/cp/invoice/${invoiceId}`,
    my_payment_methods: "/cp/paymethods",
    my_subscriptions: '/cp/subscriptions',
  
    selling: '/selling',
    sellingFacebook: '/selling/facebook',
    checkout: '/checkout',
    invite: '/invite',
    invite_code: (code: string) => `/invite/${code}`,
    register_code: (code: string) => `/register/${code}`,
    ticket: (eventId: string) => `/${eventId}/ticket`,
    category: (slug: string) => `/category/${slug}`,
    event: (eventId: string) => `/event/${eventId}`,
    events_slug: (slug: string) => `/events/${slug}`,
    shopify: `/shopify`,

    /* KwixlChat */
    kwixlchat_landing:'/kwixlchat',
    cp_kwixlchat: '/cp/kwixlchat',
    cp_kwixlchat_accounts: '/cp/kwixlchat/accounts',
    cp_kwixlchat_automations: '/cp/kwixlchat/automations',
    cp_kwixlchat_contacts: '/cp/kwixlchat/contacts',
    cp_kwixlchat_stats: '/cp/kwixlchat/stats',
    cp_kwixlchat_account: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/account`,
    cp_kwixlchat_account_automations: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/automations`,
    cp_kwixlchat_account_media: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/media`,
    cp_kwixlchat_account_contacts: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/contacts`,
    cp_kwixlchat_automation_stats_usage: (accountId = ':accountId', id = ':id') => `/cp/kwixlchat/${accountId}/usage/${id}`,
    cp_kwixlchat_automation_stats_cta: (accountId = ':accountId', id = ':id') => `/cp/kwixlchat/${accountId}/cta/${id}`,
    cp_kwixlchat_automation_flow: (accountId = ':accountId', automationId = ':automationId') => `/cp/kwixlchat/${accountId}/workflow/${automationId}`,
    cp_kwixlchat_automation_flow_new: (accountId = ':accountId') => `/cp/kwixlchat/${accountId}/workflow`,
    cp_kwixlchat_upgrade: `/cp/kwixlchat/upgrade`,
    cp_kwixlchat_uprade_check: `/cp/kwixlchat/upgrade/check`,
    cp_kwixlchat_uprade_confirm: `/cp/kwixlchat/upgrade/confirm`,
    cp_kwixlchat_get_started: '/cp/kwixlchat/getstarted',
    
    cp_integrations: '/cp/integrations',
    cp_integrations_mailchimp: '/cp/integrations/mailchimp',
    cp_integrations_klaviyo: '/cp/integrations/klaviyo',
    cp_integrations_claude: '/cp/integrations/claude',
    cp_integrations_chatgpt: '/cp/integrations/chatgpt',
    
    cp_kwixllive: '/cp/kwixllive',
    cp_kwixllive_info: '/cp/kwixllive/info',
    cp_kwixllive_events: '/cp/kwixllive/events',  
    cp_kwixllive_event_stats: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/stats`,
    cp_kwixllive_event_settings: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/settings`,
    cp_kwixllive_event_chat: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/chat`,
    cp_kwixllive_customers: '/cp/kwixllive/customers',
    cp_kwixllive_customers_groups: '/cp/kwixllive/customers/groups',
    cp_kwixllive_customers_blocked: '/cp/kwixllive/customers/blocked',
    cp_kwixllive_customers_conversation: (uid = ':uid') => `/cp/kwixllive/customers/${uid}/conversation`,
    cp_kwixllive_invoices: '/cp/kwixllive/invoices',
    cp_kwixllive_invoices_event: (id = ':eventId') => `/cp/kwixllive/invoices/event/${id}`,
    cp_kwixllive_invoices_customers: `/cp/kwixllive/invoices/customers`,
    /* LIVE EVENTS */
    cp_kwixllive_event: '/cp/kwixllive/event',
    cp_kwixllive_event_edit: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/edit`,
    cp_kwixllive_event_comments: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/comments`,
    cp_kwixllive_buyer_report: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/buyers`,
    cp_kwixllive_event_summary: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/summary`,
    cp_kwixllive_event_live: (eventId = ':eventId') => `/cp/kwixllive/event/${eventId}/live`,

    cp_kwixlshop: '/cp/kwixlshop',
    cp_kwixlshop_settings: '/cp/kwixlshop/settings',
    cp_kwixlshop_products: `/cp/kwixlshop/products`,

    cp_flow: '/cp/flow',
    cp_newdash: '/cp/newdash',
    proxy: (id = ':id') => `/r/${id}`,

  };

export const InternalRoutes = () => {
  const {stripePromise} = useSessionContext();
  return (
    <Router>
      <ScrollToTop>
        <PublicRoutes />
        <ProxyRoutes />
        <KwixlChatRoutes/>
        <AuthRoutes/>
        <Elements stripe={stripePromise}>
          <DashboardRoutes/>
          <BuyerRoutes/>
          {/*<SellerRoutes/>*/}
          {/*<EventRoutes/>*/}
          {/*<StoreRoutes/>*/}
        </Elements>
        <SupportRoutes/>
      </ScrollToTop>
    </Router>
  )
          
}