import { JSX } from 'react';
import { toast, ToastOptions } from "react-toastify";

export const flashError = (msg: string | JSX.Element, options?: ToastOptions) => 
  toast.error(msg, options);

export const flashSuccess = (msg: string | JSX.Element, options?: ToastOptions) => 
  toast.success(msg, options);

export const flashInfo = (msg: string | JSX.Element, options?: ToastOptions) => 
  toast.info(msg, options);

export const flashWarn = (msg: string | JSX.Element, options?: ToastOptions) => 
  toast.warn(msg, options);

export const flash = {
  error: flashError,
  success: flashSuccess,
  info: flashInfo,
  warn: flashWarn,
};
