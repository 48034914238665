import {Loading, Money} from 'components';
import {useFirebaseContext, useRefs, useSessionContext} from 'hooks';
import styled from '@emotion/styled';
import {StatsCard} from './StatsCard';
import {Chart} from 'react-google-charts';
import { useDocument } from 'react-firebase-hooks/firestore';

export * from './StatsCard';

interface Props {
  summary?: boolean;
}
export const OrgStats = ({summary = false}: Props) => {

  const {
    defaultListenerOptions,
  } = useFirebaseContext();

  const {organization} = useSessionContext();
  
  const {organizationPrivateStatsRef} = useRefs();

  const [stats] = useDocument(
    organizationPrivateStatsRef!(organization?.id || 'x'),
    defaultListenerOptions,
  );

  if (!organization) return <Loading>Loading your stats...</Loading>;

  const SummaryRow = () => {
    const avgTicket = Math.round((stats?.get('totalSold') || 0) / (stats?.get('invoices') || 0));
    return (
      <StatsRow>
        <div
          style={{
            float: 'right',
            padding: '10px',
            color: '#ffffff',
          }}
        >
          {/* summary && (
            <Link to={ROUTES.cp_org_stats}>
              <span style={{color: '#ffffff', fontWeight: 'bolder'}}>
                View All &gt;
              </span>
            </Link>
          )*/}
        </div>
        {stats?.get('customers') && (
          <StatsCard
            title="Customers"
            content={<Money value={stats.get('customers')} basic={true} decimal={0} />}
            description="Customers includes anyone who has purchased from you."
          />
        )}
        {stats?.get('totalSold') > 0 && (
          <StatsCard
            title="Total Sold"
            content={
              <Money value={Math.round(stats?.get('totalSold') || 0)} decimal={0} />
            }
            description="Your Total Sold amount includes all purchases by your customers."
          />
        )}
        {stats?.get('revenue') > 0 && (
          <StatsCard
            title="Total Revenue"
            content={
              <Money value={Math.round(stats?.get('revenue'))} decimal={0} />
            }
            description="Your Total Revenue includes all amounts paid by your customers less any fees."
          />
        )}
        {avgTicket > 0 && (
          <StatsCard
            title="Avg. Ticket"
            content={
              <Money
                value={avgTicket}
                decimal={0}
              />
            }
            description="The average price per ticket according to generated invoices."
          />
        )}
      </StatsRow>
    )
  };

  const FullPage = () => (
    <>
      <SummaryRow />
      <div style={{width: '400px', height: '400px'}}>
        <Chart
          options={{
            title: 'Sales',
            pieHole: 0.4,
          }}
          width={'100%'}
          height={300}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['Task', 'Hours per Day'],
            ['Sales', stats?.get('totalSold') || 0 / 100],
            ['Payments', stats?.get('revenue') || 0 / 100],
          ]}
        />
      </div>
    </>
  );

  return summary ? (
    Object.keys(stats || {}).reduce(
      (total, next) => (total += stats?.get('totalSold') || 0),
      0
    ) > 0 ? (
      <SummaryRow />
    ) : null
  ) : (
    <FullPage />
  );
};

const StatsRow = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
`;
