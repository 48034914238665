import {
  createTheme,
  alpha,
  getContrastRatio,
} from '@mui/material/styles';
import { red, grey } from '@mui/material/colors';

// Augment the palette to include a violet color
declare module '@mui/material/styles' {
  interface Palette {
    primaryAction: Palette['primary'];
    secondaryAction: Palette['primary'];
    callout: Palette['primary'];
    tertiary: Palette['primary'];
    disabled: Palette['primary'];
  }

  interface PaletteOptions {
    primaryAction: PaletteOptions['primary'];
    secondaryAction?: PaletteOptions['primary'];
    callout?: PaletteOptions['primary'];
    tertiary?: PaletteOptions['primary'];
    disabled?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include a violet option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryAction: true;
    secondaryAction: true;
    callout: true;
    tertiary: true;
    disabled: true;
  }
}

//const primaryMain = '#41436A';
//const primaryLight = alpha(primaryMain, 0.7);

//const secondaryMain = '#984063';
//const secondaryLight = alpha(secondaryMain, 0.7);

//const tertiaryMain = '#FE9677';
//const tertiaryLight = alpha(tertiaryMain, 0.7);

const violetBase = '#7226c7'; //'#7F00FF';
const violetMain = alpha(violetBase, 0.7);

const calloutBase = 'rgb(233, 114, 76)'; //'#e9724c';
const calloutMain = calloutBase; //alpha(calloutBase, 1);

const primaryActionBase = 'rgb(3, 169, 244)';
const primaryActionMain = alpha(primaryActionBase, 0.7);

const secondaryActionBase = '#800080';
const secondaryActionMain = alpha(secondaryActionBase, 0.7);

const disabledBase = '#e2e2e2';
const disabledMain = alpha(disabledBase, 0.7);

export const theme = createTheme({
  palette: {
    primary: {
      light: '#3e8abd',
      main: '#0E6DAD',
      dark: '#094c79',
      contrastText: '#fffff0',
    },
    secondary: {
      light: '#d17b41',
      main: '#c65a12',
      dark: '#8a3e0c',
      contrastText: '#fffff0',
    },
    tertiary: {
      main: alpha(violetBase, 0.8),
      light: alpha(violetBase, 0.5),
      dark: alpha(violetBase, 0.9),
      contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
    text: {
      primary: grey[900], 
      secondary: grey[600],
    },
    /*{
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  */
    error: {
      main: red.A400,
    },
    primaryAction: {
      main: primaryActionMain,
      light: alpha(primaryActionBase, 0.5),
      dark: alpha(primaryActionBase, 0.9),
      contrastText: getContrastRatio(primaryActionMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
    secondaryAction: {
      main: secondaryActionMain,
      light: alpha(secondaryActionBase, 0.5),
      dark: alpha(secondaryActionBase, 0.9),
      contrastText: getContrastRatio(secondaryActionMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
    callout: {
      main: calloutMain,
      light: alpha(calloutBase, 0.5),
      dark: alpha(calloutBase, 0.9),
      contrastText: getContrastRatio(calloutMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
    disabled: {
      main: disabledMain,
      light: alpha(disabledBase, 0.5),
      dark: alpha(disabledBase, 0.9),
      contrastText: getContrastRatio(disabledMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
});
  
/*
  {
    background: {
      color: '#121212',
    },
    primaryColor: '#03a9f4',
    callout: '#E9724C',
    font: {
      primary: {
        color: '#232B3E',
      },
      secondary: {
        color: '#e2e2e2',
      },
      disabled: {
        color: '#888888',
      },
      light: {
        color: "#fffff0",
      }
    },
    footer: {
      background: {
        color: '#232B3E',
      },
    },
    menu: {
      border: '#8C6A6F',
      background: '#232B3E',
      primary: '#FDFDFF',
      secondary: '#232B3E',
      tertiary: '#E9724C',
      active: '#03A9F4',
    },
    light: {
      font: {
        primary: {
          color: '#121212',
        },
      },
      background: {
        color: '#ffffff',
      },
    },
    dark: {
      font: {
        primary: {
          color: '#ffffff',
        },
      },
      background: {
        event: 'rgb(27,27,27)',
        color: '#121212',
      },
    },
    button: {
      primary: {
        background: '#03a9f4',
        color: '#FDFDFF',
      },
      callout: {
        background: '#E9724C',
        color: '#FDFDFF',
      }
    }
  }*/