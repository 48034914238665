import styled from '@emotion/styled';

interface Props {
  title: string;
  content: any;
  description?: string;
}

export const StatsCard = ({title, content, description}: Props) => (
  <Wrapper>
    <Title>{title || ''}</Title>
    <Content>{content || ''}</Content>
    <Description>{description || ''}</Description>
  </Wrapper>
);

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin: 0 0 10px 0;
  background-color: #fffff0;
  border: 1px solid black;
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  @media (min-width: 993px) {
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 5px;
    margin: 0 10px;
    padding: 30px 10px 10px 10px;
  }
`;

const Title = styled.div`
  display: inline-block;
  margin-top: 15px;
  text-align: center;
  font-weight: bolder;
  font-size: 1.2em;
  padding: 5px;
  margin: 0 10px 0 0;
  &:after {
    content: ':';
  }
  @media (min-width: 768px) {
    display: block;
    margin: 0 10px;
    &:after {
      content: '';
    }
  }
`;

const Content = styled.div`
  display: inline-block;
  text-align: center;
  font-weight: bolder;
  font-size: 1.25em;
  margin: 0;
  padding: 5px;
  @media (min-width: 768px) {
    display: block;
    margin: 0 10px;
  }
`;

const Description = styled.div`
  display: none;
  font-size: 0.75em;
  width: 100%;
  text-align: center;
  padding: 5px;
  @media (min-width: 768px) {
    display: block;
    font-size: 0.8em;
  }
`;
