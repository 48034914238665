import { customAlphabet } from 'nanoid';

export * from './validate';
export * from './states';
export * from './countries';
export * from './slugify';
export * from './formatCurrency';
export * from './formatNumber';
export * from './titleCase';
export * from './nullDate';
export * from './debug';
export * from './parseEmojis';
export * from './copyLink';
export {default as Api} from './Api';
export * from './FBApi';
export * from './postDate';
export * from './flash';
export * from './reducer';

/*
export {default as isMobile} from './isMobile';
*/

export const mapUserPerms = (data: Record<string, any>) => {
  if (data.perms) {
    data.isPresenter = data.perms.includes(1);
    data.isAdmin = data.perms.includes(2);
    data.canSell = data.perms.includes(3);
  }
  data.home = '/cp';
  if (data.isPresenter) {
    data.home = '/presenter';
  }
  return data;
};

export const customId = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', 16);
